const config = {
  services: {
    contract: {
      network: 'mainnet',
      validatorStakeManager: '0xf0716e6221618137fB05D72450e0FC8d9c2919d4',
      chainRegistrarOnMainchain: '0xb164c26fd7970746639151a8C118cce282F272A7',
      subchainGovernanceToken: '0x3Da3D8CDE7B12CD2CBb688E2655BcaCD8946399D',
      replaySubchainId: 77529,
    },
    theta: {
      networkName: 'Theta Mainnet',
      ethRpcUrl: 'https://eth-rpc-api.thetatoken.org/rpc',
      chainId: '361',
      chainIdHex: '0x169',
      currencySymbol: 'TFUEL',
      decimals: 18,
      blockchainExplorerUrl: 'https://explorer.thetatoken.org/',
      mainRpcUrl: 'https://theta-bridge-wallet-partner.thetatoken.org/rpc',
    },
    token: {
      rplay: {
        contract: '0x3Da3D8CDE7B12CD2CBb688E2655BcaCD8946399D',
        symbol: 'RPLAY',
        decimals: 18,
      },
    },
    validator: {
      whitelist: [],
    },
  },
  environment: 'Production',
  version: '2024.06.11.1946',
};

module.exports = config;
