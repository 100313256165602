import mainchainContract from './contract/ChainRegistrarOnMainchain.json';
import validatorStakeManagerContract from './contract/ValidatorStakeManager.json';
import SubchainGovernanceTokenContract from './contract/SubchainGovernanceToken.json';
import config from '../environments/config';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import ArticleIcon from '@mui/icons-material/Article';
import { useEffect, useState } from 'react';
import { useMetaMask } from 'metamask-react';
import { Button } from '@mui/material';
import { Snackbar } from '@mui/material';
import { isSafari, isMobileSafari } from 'react-device-detect';
import { getParsedEthersError, RETURN_VALUE_ERROR_CODES } from '@enzoferey/ethers-error-parser';
import _ from 'lodash';
import axios from 'axios';
const ethers = require('ethers');

function Staking(props) {
  const { status, connect, account, chainId, ethereum, switchChain, addChain } = useMetaMask();
  const [metamaskSupportedBrowser, setMetamaskSupportedBrowser] = useState(true);
  const [connectMetamaskDisabled, setConnectMetamaskDisabled] = useState(false);
  const [disabledMessage, setDisabledMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentBalance, setCurrentBalance] = useState(null);
  const [currentStakedRewards, setCurrentStakedRewards] = useState(null);
  const [currentMainchainHeight, setCurrentMainchainHeight] = useState(null);
  const [stakeAmount, setStakeAmount] = useState(null);
  const [unstakePercent, setUnstakePercent] = useState(null);
  const [openStakeDialog, setOpenStakeDialog] = useState(false);
  const [openUnstakeDialog, setOpenUnstakeDialog] = useState(false);
  const [pendingWithdrawals, setPendingWithdrawals] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalDescription, setModalDescription] = useState(null);
  const [enableClaim, setEnableClaim] = useState(false);
  const [stakeInputErrorMessage, setStakeInputErrorMessage] = useState(null);
  const [unstakeInputErrorMessage, setUnstakeInputErrorMessage] = useState(null);
  const [thetaWalletAccount, setThetaWalletAccount] = useState(null);
  const [thetaWalletNetwork, setThetaWalletNetwork] = useState(null);
  const validatorStakeManagerContractAddress = config.services.contract.validatorStakeManager;
  const chainRegistrarOnMainchainContractAddress =
    config.services.contract.chainRegistrarOnMainchain;
  const subchainGovernanceTokenContractAddress = config.services.token.rplay.contract;
  const validatorStakeManagerAbi = validatorStakeManagerContract.abi;
  const chainRegistrarOnMainchainAbi = mainchainContract.abi;
  const subchainGovernanceTokenAbi = SubchainGovernanceTokenContract.abi;
  const subchainID = config.services.contract.replaySubchainId;
  const tokenSymbol = config?.services?.token?.rplay?.symbol
    ? config.services.token.rplay.symbol
    : 'RPLAY';
  const MAINCHAIN_RPC_API = config?.services?.theta?.mainRpcUrl;
  const RPC_TIMEOUT_BLOCK_COUNT = 12;
  const RPC_TIMEOUT_POLLING_INTERVAL = 6000;
  const thetaChainNetworkParams = {
    chainId: config.services.theta.chainIdHex,
    chainName: config.services.theta.networkName,
    rpcUrls: [config.services.theta.ethRpcUrl],
    nativeCurrency: {
      name: config.services.theta.currencySymbol,
      symbol: config.services.theta.currencySymbol,
      decimals: config.services.theta.decimals,
    },
    blockExplorerUrls: [config.services.theta.blockchainExplorerUrl],
  };

  useEffect(() => {
    if (isSafari || isMobileSafari) {
      setMetamaskSupportedBrowser(false);
    }
  }, []);

  useEffect(() => {
    const isThetaWallet = Boolean(window.ethereum && window.ethereum.isThetaWallet);
    if (isThetaWallet) {
      // User is connected through mobile Theta Wallet, auto connect them
      getThetaWalletAccounts();
    }
    // eslint-disable-next-line
  }, [window.ethereum]);

  const getThetaWalletAccounts = async () => {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      if (accounts && accounts?.length > 0) {
        setThetaWalletAccount(accounts[0]);
        setThetaWalletNetwork(window.ethereum.networkVersion);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let disabled = false;
    let message = '';

    if (!currentAccount) {
      message = 'Please connect your account.';
      setDisabledMessage(message);
    }

    // eslint-disable-next-line
    if ((chainId !== config?.services?.theta?.chainIdHex && !thetaWalletAccount) || (thetaWalletAccount && window.ethereum.networkVersion !== config?.services?.theta?.chainId)) {
      message = 'Please connect to the ' + config?.services?.theta?.networkName + ' network.';
      if (thetaWalletAccount) {
        disabled = true;
      }
      setDisabledMessage(message);
    }

    if (status === 'unavailable' && !thetaWalletAccount) {
      disabled = true;
      message = 'Please install the MetaMask browser extension.';
    }

    if (!metamaskSupportedBrowser && !thetaWalletAccount) {
      disabled = true;
      message = 'MetaMask browser extension not supported. Please use a supported browser.';
    }

    if (disabled) {
      setConnectMetamaskDisabled(true);
      setDisabledMessage(message);
    } else {
      setConnectMetamaskDisabled(false);
      setDisabledMessage('');
    }
  }, [currentAccount, status, chainId, metamaskSupportedBrowser, thetaWalletAccount]);

  // Check the current network and switch
  useEffect(() => {
    const switchNetwork = async () => {
      try {
        await switchChain(config?.services?.theta?.chainIdHex);
      } catch (err) {
        console.error(err);
        if (err?.code === 4902) {
          addChain(thetaChainNetworkParams);
        }
      }
    };

    if (
      (status === 'notConnected' || status === 'connected') &&
      chainId !== config?.services?.theta?.chainIdHex
    ) {
      switchNetwork();
    }
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    if (thetaWalletAccount) {
      setCurrentAccount(thetaWalletAccount?.toLowerCase());
    } else if (status === 'connected') {
      setCurrentAccount(account);
    }
  }, [status, account, thetaWalletAccount]);

  useEffect(() => {
    // eslint-disable-next-line
    if (((currentAccount && chainId === config?.services?.theta?.chainIdHex) || (currentAccount && thetaWalletAccount && window.ethereum.networkVersion === config?.services?.theta?.chainId)) && currentBalance === null) {
      getCurrentHeight();
      getBalance();
      getStakedAndRewards();
      getPendingWithdrawals();
    } else {
      setCurrentBalance(null);
      setCurrentStakedRewards(null);
      setPendingWithdrawals([]);
      setCurrentMainchainHeight(null);
    }
    // eslint-disable-next-line
  }, [currentAccount, chainId, thetaWalletAccount]);

  useEffect(() => {
    if (stakeAmount !== null && (stakeAmount <= 0.0 || stakeAmount > Number(currentBalance))) {
      setStakeInputErrorMessage('Please enter a value between 0.00 and your balance.');
    } else {
      setStakeInputErrorMessage(null);
    }
    // eslint-disable-next-line
  }, [stakeAmount]);

  useEffect(() => {
    if (unstakePercent !== null && (unstakePercent <= 0 || unstakePercent > 100)) {
      setUnstakeInputErrorMessage('Please enter a value between 0 and 100.');
    } else {
      setUnstakeInputErrorMessage(null);
    }
  }, [unstakePercent]);

  const getTransactionStatus = async (hash) => {
    let transaction = null;
    try {
      const payload = {
        id: 1,
        jsonrpc: '2.0',
        method: 'theta.GetTransaction',
        params: [
          {
            hash: hash,
          },
        ],
      };
      const response = await axios.post(MAINCHAIN_RPC_API, payload, {});
      const data = await response.data;
      if (data?.result) {
        transaction = data.result;
      }
    } catch (error) {
      console.error(error?.message);
    }
    return transaction;
  };

  const getBalance = async () => {
    try {
      const provider = getWeb3Provider();
      const subchainGovernanceTokenContract = new ethers.Contract(
        subchainGovernanceTokenContractAddress,
        subchainGovernanceTokenAbi
      );
      const rplayTokenContract = subchainGovernanceTokenContract.connect(provider);
      const balance = await rplayTokenContract.balanceOf(currentAccount);
      if (balance) {
        setCurrentBalance(ethers.formatEther(balance));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getStakedAndRewards = async () => {
    try {
      let rplayStakedByUser = null;
      const provider = getWeb3Provider();
      const validatorStakeManagerContract = new ethers.Contract(
        validatorStakeManagerContractAddress,
        validatorStakeManagerAbi
      );
      const validatorStakeManagerContractConnect = validatorStakeManagerContract.connect(provider);
      const governanceTokenOwnedResponse =
        await validatorStakeManagerContractConnect.estimatedGovernanceTokenOwnedBy(
          subchainID,
          currentAccount
        );
      if (governanceTokenOwnedResponse) {
        rplayStakedByUser = ethers.formatEther(governanceTokenOwnedResponse);
        if (rplayStakedByUser) {
          setCurrentStakedRewards(rplayStakedByUser);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getPendingWithdrawals = async () => {
    try {
      const provider = getWeb3Provider();
      const mainChainContract = new ethers.Contract(
        chainRegistrarOnMainchainContractAddress,
        chainRegistrarOnMainchainAbi
      );
      const mainChainContractConnect = mainChainContract.connect(provider);
      const pendingWithdrawalsResponse = await mainChainContractConnect.getPendingStakeWithdrawals(
        subchainID,
        currentAccount
      );
      if (pendingWithdrawalsResponse && pendingWithdrawalsResponse.length > 0) {
        setPendingWithdrawals(pendingWithdrawalsResponse);

        let currentMainchainHeight = null;
        if (!currentMainchainHeight) {
          currentMainchainHeight = await getCurrentHeight();
        }
        for (let i = 0; i < pendingWithdrawalsResponse.length; i++) {
          const returnHeight = pendingWithdrawalsResponse[i]?.returnHeight;
          const blockDifference = BigInt(currentMainchainHeight) - returnHeight;
          console.log('withdrawal block difference', blockDifference);
          if (BigInt(currentMainchainHeight) > returnHeight) {
            setEnableClaim(true);
            break;
          }
        }
      }
    } catch (err) {
      console.error('Error getting pending withdrawals', err);
    }
  };

  const getValidator = async (type) => {
    const provider = getWeb3Provider();
    const dynasty = await getDynasty();
    const validatorStakeManagerContract = new ethers.Contract(
      validatorStakeManagerContractAddress,
      validatorStakeManagerAbi
    );
    const validatorStakeManagerContractConnect = validatorStakeManagerContract.connect(provider);
    const vs = await validatorStakeManagerContractConnect.getValidatorSet(subchainID, dynasty);

    let response = {
      validatorAddresses: [],
      unstakeAmounts: [],
    };
    if (
      vs?.validators &&
      vs?.validators.length > 0 &&
      vs?.shareAmounts &&
      vs?.shareAmounts.length > 0
    ) {
      // Check if the user has already staked before
      let stakerTotalShares = await validatorStakeManagerContractConnect.shareOf(
        subchainID,
        currentAccount
      );

      if (type === 'unstake') {
        const unstakeAmount =
          (stakerTotalShares * ethers.parseEther(unstakePercent + '')) / ethers.parseEther('100');
        console.log('unstake amount', unstakeAmount);
        let withdrawAmountLeft = unstakeAmount;

        for (let i = 0; i < vs.validators.length; i++) {
          const shares = await validatorStakeManagerContractConnect.getStakerShares(
            subchainID,
            vs.validators[i],
            currentAccount
          );
          if (withdrawAmountLeft > 0n && shares > 0n && shares >= withdrawAmountLeft) {
            response?.validatorAddresses?.push(vs.validators[i]);
            response?.unstakeAmounts?.push(withdrawAmountLeft);
            withdrawAmountLeft = 0n;
          } else if (withdrawAmountLeft > 0n && shares > 0n && shares < withdrawAmountLeft) {
            withdrawAmountLeft = withdrawAmountLeft - shares;
            response?.validatorAddresses?.push(vs.validators[i]);
            response?.unstakeAmounts?.push(shares);
          }
        }
      } else {
        // stake
        if (stakerTotalShares > BigInt(0)) {
          // Use the same validator they staked against
          for (let i = 0; i < vs.validators.length; i++) {
            const shares = await validatorStakeManagerContractConnect.getStakerShares(
              subchainID,
              vs.validators[i],
              currentAccount
            );
            if (shares > BigInt(0)) {
              try {
                response?.validatorAddresses?.push(vs.validators[i]);
                break;
              } catch (e) {
                console.error(e);
              }
            }
          }
        } else {
          // Find the validator with the lowest share amount
          response?.validatorAddresses?.push(
            findLowestShareValidator(vs.validators, vs.shareAmounts)
          );
        }
      }
    } else {
      console.error('Error getting validators');
    }

    console.log('validators', response);
    return response;
  };

  const getDynasty = async () => {
    const provider = getWeb3Provider();
    const mainChainContract = new ethers.Contract(
      chainRegistrarOnMainchainContractAddress,
      chainRegistrarOnMainchainAbi
    );
    const mainChainContractConnect = mainChainContract.connect(provider);
    let dynasty = null;
    const dynastyResponse = await mainChainContractConnect.getDynasty();
    if (dynastyResponse?.success) {
      dynasty = dynastyResponse?.dynasty;
    }

    console.log('dynasty', dynasty);
    return dynasty;
  };

  const getCurrentHeight = async () => {
    let currentHeight = null;
    try {
      const payload = {
        id: 1,
        jsonrpc: '2.0',
        method: 'theta.GetStatus',
        params: [],
      };
      const response = await axios.post(MAINCHAIN_RPC_API, payload, {});
      const data = await response.data;
      if (data?.result?.current_height) {
        setCurrentMainchainHeight(data?.result?.current_height);
        return data?.result?.current_height;
      }
    } catch (error) {
      console.error(error?.message);
    }

    console.log('current block height', currentHeight);
    return currentHeight;
  };

  const stakeHandler = async (e) => {
    e.preventDefault();
    try {
      // GET VALIDATOR ADDRESS
      const validatorResponse = await getValidator('stake');

      let validatorAddress = null;
      if (
        validatorResponse &&
        validatorResponse.validatorAddresses &&
        validatorResponse.validatorAddresses.length > 0
      ) {
        validatorAddress = validatorResponse.validatorAddresses[0];
      }

      let stakeAmountConverted = null;
      try {
        stakeAmountConverted = ethers.parseEther(stakeAmount + '');
      } catch (e) {
        console.error(e);
      }

      if (validatorAddress && stakeAmountConverted) {
        // Check the Allowance required to stake entered amount
        const provider = getWeb3Provider();
        const signer = await provider.getSigner(currentAccount);

        const subchainGovernanceTokenContract = new ethers.Contract(
          subchainGovernanceTokenContractAddress,
          subchainGovernanceTokenAbi
        );
        const rplayTokenSigner = subchainGovernanceTokenContract.connect(signer);
        const allowance = await rplayTokenSigner.allowance(
          currentAccount,
          validatorStakeManagerContractAddress
        );

        setOpenStakeDialog(false);

        if (allowance < stakeAmountConverted) {
          try {
            handleOpenModal(
              'Allowance required (Transaction 1/2)',
              'Please review and sign the transaction.'
            );
            const tx = await rplayTokenSigner.approve(
              validatorStakeManagerContractAddress,
              stakeAmountConverted
            );
            console.log('approval transaction', tx);
            const approvalTxHash = tx?.hash;
            console.log('approval transaction hash', approvalTxHash);
            handleOpenModal('Waiting...', 'Approval is being processed.');
            const receipt = await tx.wait(2);
            handleCloseModal();
            if (receipt?.hash) {
              updateSnackbar('Allowance approved, proceeding with staking process.', 'info', true);
            } else {
              updateSnackbar(
                'An error occurred during allowance approval. Please try again later.',
                'error',
                true
              );
            }
          } catch (err) {
            const parsedEthersError = getParsedEthersError(err);
            handleCloseModal();
            if (parsedEthersError.errorCode === RETURN_VALUE_ERROR_CODES.REJECTED_TRANSACTION) {
              handleCloseStakeDialog();
              updateSnackbar('Allowance approval has been denied.', 'warning', true);
            } else {
              handleCloseStakeDialog();
              updateSnackbar(
                'An error occurred during allowance approval. Please try again later.',
                'error',
                true
              );
            }
            return;
          }
        }

        // Deposit the stake amount
        const providerForDeposit = getWeb3Provider();
        const signerForDeposit = await providerForDeposit.getSigner(currentAccount);
        const mainChainContractForDeposit = new ethers.Contract(
          chainRegistrarOnMainchainContractAddress,
          chainRegistrarOnMainchainAbi,
          signerForDeposit
        );
        const mainChainSigner = mainChainContractForDeposit.connect(signerForDeposit);
        handleOpenModal(
          allowance < stakeAmountConverted ? 'Stake (Transaction 2/2)' : 'Stake',
          'Please review and sign the transaction.'
        );

        try {
          const depositTx = await mainChainSigner.depositStake(
            subchainID,
            validatorAddress,
            stakeAmountConverted
          );
          console.log('deposit transaction', depositTx);
          const depositTxHash = depositTx?.hash;
          console.log('deposit transaction hash', depositTxHash);
          handleOpenModal('Waiting...', 'Transaction is being processed.');

          if (depositTxHash) {
            let count = 0;
            const transactionStatusResponse = await new Promise(async (resolve) => {
              const interval = setInterval(async () => {
                count++;
                let transactionStatus = null;
                try {
                  transactionStatus = await getTransactionStatus(depositTxHash);
                  console.log('transaction status:', transactionStatus);
                } catch (err) {
                  console.error(err);
                }

                const status = transactionStatus?.status;
                const error = transactionStatus?.receipt?.EvmErr;

                if (count < RPC_TIMEOUT_BLOCK_COUNT && status === 'finalized') {
                  handleCloseModal();
                  handleCloseStakeDialog();
                  if (error) {
                    updateSnackbar(
                      'An error occurred during staking. Please try again later.',
                      'error',
                      true
                    );
                  } else {
                    updateSnackbar('Staking successful!', 'success', true);
                    // Update the balance + staked/reward amounts and pending withdrawals
                    getBalance();
                    getStakedAndRewards();
                    getPendingWithdrawals();
                  }
                  // clear interval once finalized
                  clearInterval(interval);
                  return resolve(transactionStatus);
                } else if (count === RPC_TIMEOUT_BLOCK_COUNT) {
                  // clear interval once maximum timeout reached
                  clearInterval(interval);
                  handleCloseModal();
                  const msg = (
                    <Typography display="block" className="mb-4">
                      Please check the status of your staking transaction on
                      <Link
                        className="pl-4"
                        href={config.services.theta.blockchainExplorerUrl + 'txs/' + depositTxHash}
                        target="_blank"
                        rel="noopener"
                        style={{
                          textDecoration: 'underline',
                          background: 'none',
                          border: 'none',
                          color: 'white',
                        }}
                      >
                        Theta Explorer
                      </Link>
                      .
                    </Typography>
                  );
                  updateSnackbar(msg, 'error', true);
                  return resolve(transactionStatus);
                }
              }, RPC_TIMEOUT_POLLING_INTERVAL);
            });
          } else {
            console.error('An error occurred during staking.');
            handleCloseModal();
            handleCloseStakeDialog();
            updateSnackbar(
              'An error occurred during staking. Please try again later.',
              'error',
              true
            );
          }
        } catch (err) {
          const parsedEthersError = getParsedEthersError(err);
          handleCloseModal();
          if (parsedEthersError.errorCode === RETURN_VALUE_ERROR_CODES.REJECTED_TRANSACTION) {
            handleCloseStakeDialog();
            updateSnackbar('The transaction has been denied.', 'warning', true);
          } else {
            console.error(err);
            handleCloseStakeDialog();
            updateSnackbar(
              'An error occurred during staking. Please try again later.',
              'error',
              true
            );
          }
          return;
        }
      }
    } catch (err) {
      console.error(err);
      handleCloseModal();
      handleCloseStakeDialog();
      updateSnackbar('An error occurred during staking. Please try again later.', 'error', true);
    }
  };

  const unstakeHandler = async (e) => {
    e.preventDefault();
    try {
      const validatorResponse = await getValidator('unstake');

      if (
        validatorResponse &&
        validatorResponse.validatorAddresses &&
        validatorResponse.validatorAddresses.length > 0 &&
        validatorResponse.unstakeAmounts &&
        validatorResponse.unstakeAmounts.length > 0
      ) {
        let counter = 1;
        for (let i = 0; i < validatorResponse.validatorAddresses.length; i++) {
          const providerForWithdraw = getWeb3Provider();
          const signerForWithdraw = await providerForWithdraw.getSigner(currentAccount);
          const mainChainContractForWithdraw = new ethers.Contract(
            chainRegistrarOnMainchainContractAddress,
            chainRegistrarOnMainchainAbi,
            signerForWithdraw
          );
          const mainChainSigner = mainChainContractForWithdraw.connect(signerForWithdraw);
          setOpenUnstakeDialog(false);

          let unstakeTitle = null;
          if (validatorResponse.validatorAddresses.length > 1) {
            unstakeTitle =
              'Unstake (Transaction ' +
              counter +
              '/' +
              validatorResponse.validatorAddresses.length +
              ')';
          } else {
            unstakeTitle = 'Unstake';
          }

          handleOpenModal(unstakeTitle, 'Please review and sign the transaction.');

          try {
            const withdrawTx = await mainChainSigner.withdrawStake(
              subchainID,
              validatorResponse.validatorAddresses[i],
              validatorResponse.unstakeAmounts[i]
            );
            console.log('withdrawal transaction', withdrawTx);
            const withdrawalTxHash = withdrawTx?.hash;
            console.log('withdraw transaction hash', withdrawalTxHash);
            handleOpenModal('Waiting...', 'Transaction is being processed.');

            if (withdrawalTxHash) {
              let count = 0;
              const transactionStatusResponse = await new Promise(async (resolve) => {
                const interval = setInterval(async () => {
                  count++;
                  let transactionStatus = null;
                  try {
                    transactionStatus = await getTransactionStatus(withdrawalTxHash);
                    console.log('transaction status:', transactionStatus);
                  } catch (err) {
                    console.error(err);
                  }

                  const status = transactionStatus?.status;
                  const error = transactionStatus?.receipt?.EvmErr;

                  if (count < RPC_TIMEOUT_BLOCK_COUNT && status === 'finalized') {
                    handleCloseModal();
                    handleCloseUnstakeDialog();
                    if (error) {
                      updateSnackbar(
                        'An error occurred during unstaking. Please try again later.',
                        'error',
                        true
                      );
                    } else {
                      updateSnackbar('Unstaking successful!', 'success', true);
                      // Update the balance + staked/reward amounts and pending withdrawals
                      getBalance();
                      getStakedAndRewards();
                      getPendingWithdrawals();
                    }
                    // clear interval once finalized
                    clearInterval(interval);
                    return resolve(transactionStatus);
                  } else if (count === RPC_TIMEOUT_BLOCK_COUNT) {
                    // clear interval once maximum timeout reached
                    clearInterval(interval);
                    handleCloseModal();
                    const msg = (
                      <Typography display="block" className="mb-4">
                        Please check the status of your unstaking transaction on
                        <Link
                          className="pl-4"
                          href={
                            config.services.theta.blockchainExplorerUrl + 'txs/' + withdrawalTxHash
                          }
                          target="_blank"
                          rel="noopener"
                          style={{
                            textDecoration: 'underline',
                            background: 'none',
                            border: 'none',
                            color: 'white',
                          }}
                        >
                          Theta Explorer
                        </Link>
                        .
                      </Typography>
                    );
                    updateSnackbar(msg, 'error', true);
                    return resolve(transactionStatus);
                  }
                }, RPC_TIMEOUT_POLLING_INTERVAL);
              });
            } else {
              console.error('An error occurred during unstaking.');
              handleCloseModal();
              handleCloseStakeDialog();
              updateSnackbar(
                'An error occurred during unstaking. Please try again later.',
                'error',
                true
              );
            }
          } catch (err) {
            const parsedEthersError = getParsedEthersError(err);
            handleCloseModal();
            if (parsedEthersError.errorCode === RETURN_VALUE_ERROR_CODES.REJECTED_TRANSACTION) {
              handleCloseUnstakeDialog();
              updateSnackbar('The transaction has been denied.', 'warning', true);
            } else {
              handleCloseUnstakeDialog();
              updateSnackbar(
                'An error occurred during unstaking. Please try again later.',
                'error',
                true
              );
            }
            return;
          }

          counter++;
        }
      }
    } catch (err) {
      console.error(err);
      handleCloseModal();
      handleCloseUnstakeDialog();
      updateSnackbar('An error occurred during unstaking. Please try again later.', 'error', true);
    }
  };

  const claimHandler = async (e) => {
    e.preventDefault();
    try {
      const providerForClaim = getWeb3Provider();
      const signerForClaim = await providerForClaim.getSigner(currentAccount);
      const mainChainContractForClaim = new ethers.Contract(
        chainRegistrarOnMainchainContractAddress,
        chainRegistrarOnMainchainAbi,
        signerForClaim
      );
      const mainChainSigner = mainChainContractForClaim.connect(signerForClaim);
      handleOpenModal('Claim', 'Please review and sign the transaction.');

      try {
        const claimTx = await mainChainSigner.claimStake(subchainID);
        console.log('claim transaction', claimTx);
        const claimTxHash = claimTx?.hash;
        console.log('claim transaction hash', claimTxHash);
        handleOpenModal('Waiting...', 'Transaction is being processed.');

        if (claimTxHash) {
          let count = 0;
          const transactionStatusResponse = await new Promise(async (resolve) => {
            const interval = setInterval(async () => {
              count++;
              let transactionStatus = null;
              try {
                transactionStatus = await getTransactionStatus(claimTxHash);
                console.log('transaction status:', transactionStatus);
              } catch (err) {
                console.error(err);
              }

              const status = transactionStatus?.status;
              const error = transactionStatus?.receipt?.EvmErr;

              if (count < RPC_TIMEOUT_BLOCK_COUNT && status === 'finalized') {
                handleCloseModal();
                if (error) {
                  updateSnackbar(
                    'An error occurred during claim. Please try again later.',
                    'error',
                    true
                  );
                } else {
                  updateSnackbar('Claim successful!', 'success', true);
                  // Update the balance + staked/reward amounts and pending withdrawals
                  getBalance();
                  getStakedAndRewards();
                  getPendingWithdrawals();
                }
                // clear interval once finalized
                clearInterval(interval);
                return resolve(transactionStatus);
              } else if (count === RPC_TIMEOUT_BLOCK_COUNT) {
                // clear interval once maximum timeout reached
                clearInterval(interval);
                handleCloseModal();
                const msg = (
                  <Typography display="block" className="mb-4">
                    Please check the status of your claim transaction on
                    <Link
                      className="pl-4"
                      href={config.services.theta.blockchainExplorerUrl + 'txs/' + claimTxHash}
                      target="_blank"
                      rel="noopener"
                      style={{
                        textDecoration: 'underline',
                        background: 'none',
                        border: 'none',
                        color: 'white',
                      }}
                    >
                      Theta Explorer
                    </Link>
                    .
                  </Typography>
                );
                updateSnackbar(msg, 'error', true);
                return resolve(transactionStatus);
              }
            }, RPC_TIMEOUT_POLLING_INTERVAL);
          });
        } else {
          console.error('An error occurred during claim.');
          handleCloseModal();
          handleCloseStakeDialog();
          updateSnackbar('An error occurred during claim. Please try again later.', 'error', true);
        }
      } catch (err) {
        const parsedEthersError = getParsedEthersError(err);
        handleCloseModal();
        if (parsedEthersError.errorCode === RETURN_VALUE_ERROR_CODES.REJECTED_TRANSACTION) {
          updateSnackbar('The transaction has been denied.', 'warning', true);
        } else {
          updateSnackbar('An error occurred during claim. Please try again later.', 'error', true);
        }
        return;
      }
    } catch (err) {
      console.error(err);
      handleCloseModal();
      updateSnackbar('An error occurred during claim. Please try again later.', 'error', true);
    }
  };

  const connectWalletHandler = async () => {
    if (status === 'notConnected' || status === 'connected') {
      try {
        connect();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getWeb3Provider = () => {
    if (ethereum) {
      return new ethers.BrowserProvider(ethereum);
    } else if (thetaWalletAccount) {
      return new ethers.BrowserProvider(window.ethereum);
    }
    return null;
  };

  const addRplayTokenToMetaMask = async () => {
    if (!ethereum) {
      return;
    }
    try {
      const added = await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: subchainGovernanceTokenContractAddress,
            symbol: config.services.token.rplay.symbol,
            decimals: config.services.token.rplay.decimals,
            image: 'https://rewards.imaginereplay.com/assets/images/logos/app_replay.svg',
          },
        },
      });
      return added;
    } catch (error) {
      return;
    }
  };

  // Pick the validator with the lowest share amount to stake
  const findLowestShareValidator = (addresses, shareAmounts) => {
    const validatorWhitelist = config?.services?.validator?.whitelist;
    let validatorAddress = addresses[0];
    let index = 0;

    if (validatorWhitelist && validatorWhitelist.length > 0) {
      // use validator addresses from config file
      let low = null;
      let found = false;
      for (let i = 0; i < validatorWhitelist.length; i++) {
        let validIndex = _.findIndex(
          addresses,
          (v) => {
            return v?.toLowerCase() === validatorWhitelist[i]?.toLowerCase();
          },
          0
        );

        if (validIndex !== -1) {
          // disregard invalid addresses
          found = true;
          if (low === null || low >= shareAmounts[validIndex]) {
            low = shareAmounts[validIndex];
            index = i;
          }
        }
      }
      if (found) {
        try {
          validatorAddress = validatorWhitelist[index];
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      // use validator addresses from contract
      let low = shareAmounts[0];
      for (let i = 1; i < shareAmounts.length; i++) {
        if (low >= shareAmounts[i]) {
          low = shareAmounts[i];
          index = i;
        }
      }
      try {
        validatorAddress = addresses[index];
      } catch (e) {
        console.error(e);
      }
    }

    return validatorAddress;
  };

  const maskAddress = (addr) => {
    if (addr) {
      return addr.substring(0, 6) + '...' + addr.substring(addr.length - 4, addr.length);
    }
    return addr;
  };

  const handleCopyClickByParam = (address) => {
    if (address) {
      updateSnackbar('Copied to clipboard.', 'info', true);
      navigator.clipboard.writeText(address);
    }
  };

  const getPendingWithdrawalText = (withdrawal) => {
    const returnHeight = withdrawal?.returnHeight;
    const blockDifference = BigInt(currentMainchainHeight) - returnHeight;
    if (blockDifference > 0) {
      // ready to claim
      return 'Available to claim';
    } else {
      // pending
      let hoursLeft = (absBigInt(blockDifference) * 6n) / 60n / 60n;
      const daysLeft = Math.round(Number(hoursLeft) / 24);
      if (daysLeft === 0) {
        return 'Available in ~' + hoursLeft?.toString() + ' hour(s)';
      } else if (daysLeft === 1) {
        return 'Available in ~' + daysLeft?.toString() + ' day';
      } else {
        return 'Available in ~' + daysLeft?.toString() + ' days';
      }
    }
  };

  const absBigInt = (n) => (n === -0 || n < 0n ? -n : n);

  const handleCloseStakeDialog = () => {
    setStakeAmount(null);
    setOpenStakeDialog(false);
  };

  const handleCloseUnstakeDialog = () => {
    setUnstakePercent(null);
    setOpenUnstakeDialog(false);
  };

  const handleOpenModal = (title, description) => {
    setModalTitle(title);
    setModalDescription(description);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setModalTitle(null);
    setModalDescription(null);
    setOpenModal(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setSnackbarSeverity(null);
  };

  const updateSnackbar = (message, severity, open) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(open);
  };

  return (
    <div className="w-full font-futuraPtBook">
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        className="flex flex-start h-full pt-8 md:pt-16 px-16 md:px-16"
      >
        <div>
          <Link href="https://imaginereplay.org/" target="_blank">
            <img
              className="logo-icon w-160"
              src="assets/images/logos/replay-logo-primary-dark_300DPI.png"
              alt="logo"
            />
          </Link>
        </div>
        <div>
          <Button
            href="https://docs.imaginereplay.com"
            variant="contained"
            startIcon={<ArticleIcon />}
            target="_blank"
            color="primary"
            className="px-16 bg-[#34B318] text-white hover:bg-[#34B318] text-14 rounded-3xl font-bold normal-case"
          >
            Docs
          </Button>
        </div>
      </Stack>
      <div className="rounded-xl w-full my-0 mx-auto max-w-[1100px]">
        <div className="py-48 px-24" align="center">
          <div>
            <Typography className="text-44 font-bold font-futuraPtHeavy">
              <span className="text-[#33B319]">Stake {tokenSymbol} </span>
              to help secure the Replay Theta subchain and earn rewards.
            </Typography>
            <h3 className="text-18 font-futuraPtBook mt-16">
              Unstake your {tokenSymbol} at any time including pending rewards subject to a cooldown
              period.
            </h3>
          </div>

          {snackbarMessage && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={handleSnackbarClose}
              open={openSnackbar}
              key={Math.random()}
            >
              <Alert
                variant="filled"
                onClose={handleSnackbarClose}
                severity={snackbarSeverity ? snackbarSeverity : 'info'}
                sx={{ width: '100%', borderRadius: '3rem' }}
                className={snackbarSeverity === 'success' ? 'bg-replayGreen text-white' : ''}
                action={
                  <>
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      sx={{ p: 0.5 }}
                      onClick={handleSnackbarClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                }
              >
                <span className="text-[1.3rem]">{snackbarMessage}</span>
              </Alert>
            </Snackbar>
          )}

          {modalTitle && (
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
              }}
            >
              <div align="center">
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    color: 'white',
                    p: 4,
                  }}
                >
                  <CircularProgress thickness={5} size="3.6rem" className="text-[#33B319]" />
                  <Typography variant="h6" component="h2" className="mt-2 text-[1.8rem]">
                    {modalTitle}
                  </Typography>
                  <Typography sx={{ mt: 2 }} className="text-gray-500 text-[1.3rem]">
                    {modalDescription}
                  </Typography>
                </Box>
              </div>
            </Modal>
          )}

          <div className="rounded-xl bg-white p-36 mt-36 md:w-[60%]">
            {(currentAccount && chainId === config?.services?.theta?.chainIdHex) ||
            (currentAccount &&
              thetaWalletAccount &&
              window.ethereum.networkVersion === config?.services?.theta?.chainId) ? (
              <>
                <Stack
                  direction="row"
                  spacing={1}
                  className="flex items-center"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div>
                    <Stack direction="row" spacing={1} className="flex" alignItems="center">
                      <img className="h-24" src="assets/images/logos/metamask.png" alt="logo" />
                      <Typography component="span" className="text-14 font-futuraPtBook">
                        Connected Wallet:{' '}
                        <span className="font-bold text-replayGreen">
                          {maskAddress(currentAccount)}
                        </span>
                      </Typography>
                      <IconButton
                        onClick={() => handleCopyClickByParam(currentAccount)}
                        className="text-[#090B0A] align-baseline p-[5px]"
                      >
                        <ContentCopyIcon style={{ fontSize: '1.1rem' }} />
                      </IconButton>
                    </Stack>
                  </div>
                  <Link
                    onClick={() => setCurrentAccount(null)}
                    className="text-14 font-bold font-futuraPtDemi"
                    component="button"
                    style={{
                      textDecoration: 'underline',
                      background: 'none',
                      border: 'none',
                      color: 'black',
                    }}
                  >
                    DISCONNECT
                  </Link>
                </Stack>

                <Grid
                  container
                  spacing={3}
                  className="flex mt-4"
                  alignItems="center"
                  justifyContent="left"
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    flex
                    alignItems="center"
                    justifyContent="left"
                  >
                    <div align="left">
                      <Typography
                        className={`text-15 font-futuraPtHeavy uppercase tracking-wide leading-normal text-replayGreen`}
                      >
                        Balance
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: 800 }}
                        className={`text-24 font-futuraPtHeavy text-[#090B0A] leading-tight`}
                        title={currentBalance + ' ' + tokenSymbol}
                      >
                        {currentBalance
                          ? Number(currentBalance).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) +
                            ' ' +
                            tokenSymbol
                          : '0.00 ' + tokenSymbol}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    flex
                    alignItems="center"
                    justifyContent="center"
                  >
                    <div align="right">
                      <Button
                        disabled={!currentBalance || !(Number(currentBalance) > 0.0)}
                        variant="contained"
                        color="secondary"
                        className="text-15 w-[100%] md:w-[70%] font-futuraPtHeavy bg-[#33B319] text-white font-bold rounded-[3rem] hover:bg-[#33B319] hover:bg-opacity-70"
                        onClick={() => setOpenStakeDialog(true)}
                      >
                        STAKE
                      </Button>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    flex
                    alignItems="center"
                    justifyContent="left"
                  >
                    <div align="left">
                      <Typography
                        className={`text-15 font-futuraPtHeavy uppercase tracking-wide leading-normal text-replayGreen`}
                      >
                        Staked + Reward (Est.)
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: 800 }}
                        className={`text-24 font-futuraPtHeavy text-[#090B0A] leading-tight`}
                        title={currentStakedRewards + ' ' + tokenSymbol}
                      >
                        {currentStakedRewards
                          ? Number(currentStakedRewards).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) +
                            ' ' +
                            tokenSymbol
                          : '0.00 ' + tokenSymbol}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    flex
                    alignItems="center"
                    justifyContent="center"
                  >
                    <div align="right">
                      <Button
                        disabled={!currentStakedRewards || !(Number(currentStakedRewards) > 0.0)}
                        variant="contained"
                        color="primary"
                        className="text-15 w-[100%] md:w-[70%] font-futuraPtHeavy bg-[#090B0A] text-white font-bold rounded-[3rem] hover:bg-[#090B0A] hover:bg-opacity-70"
                        onClick={() => setOpenUnstakeDialog(true)}
                      >
                        UNSTAKE
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {disabledMessage ? (
                  <Typography className="text-14 font-futuraPtBook">{disabledMessage}</Typography>
                ) : (
                  <Typography className="text-14 uppercase font-futuraPtBook">
                    Connect your Wallet
                  </Typography>
                )}
              </>
            )}

            {(!currentAccount ||
              (chainId !== config?.services?.theta?.chainIdHex && !thetaWalletAccount) ||
              (window.ethereum.networkVersion !== config?.services?.theta?.chainId &&
                thetaWalletAccount)) && (
              <div className="mt-16">
                <Button
                  role="button"
                  variant="contained"
                  color="primary"
                  className="bg-[#DFDFDF] p-16 w-320 hover:text-white hover:bg-[#c2c2c2] rounded-[3rem]"
                  onClick={connectWalletHandler}
                  disabled={connectMetamaskDisabled}
                >
                  <img className="h-24" src="assets/images/logos/metamask-logo.png" alt="logo" />
                </Button>
              </div>
            )}
          </div>

          {pendingWithdrawals && pendingWithdrawals.length > 0 && currentMainchainHeight && (
            <div className="rounded-xl bg-white px-36 pt-36 pb-20 mt-36 md:w-[60%]">
              <Grid item xs={12} flex justifyContent="left">
                <div align="left">
                  <Typography
                    className={`text-15 font-futuraPtHeavy uppercase tracking-wide leading-normal text-replayGreen`}
                  >
                    PENDING WITHDRAWALS
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    flex
                    alignItems="center"
                    justifyContent="left"
                    className="mt-8"
                  >
                    {pendingWithdrawals.map((item, index) => (
                      <div key={index} className="mt-4">
                        {index !== 0 && <Divider variant="fullWidth" className="border-gray-400" />}
                        <div className="py-16">
                          <Stack
                            direction="row"
                            spacing={1}
                            className="flex items-center"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                              variant="h6"
                              className={`text-18 text-[#090B0A] leading-tight font-futuraPtHeavy`}
                              title={ethers.formatEther(item?.amount) + ' ' + tokenSymbol}
                            >
                              {Number(ethers.formatEther(item?.amount)).toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) +
                                ' ' +
                                tokenSymbol}
                            </Typography>
                            <Typography
                              variant="h6"
                              className={`text-16 font-futuraPtBook text-[#090B0A] leading-tight `}
                            >
                              {getPendingWithdrawalText(item)}
                            </Typography>
                          </Stack>
                        </div>
                      </div>
                    ))}
                  </Grid>
                  {enableClaim && (
                    <div align="center" className="pt-16 pb-20">
                      <Button
                        disabled={!enableClaim}
                        variant="contained"
                        color="secondary"
                        className="text-15 font-futuraPtHeavy w-[100%] md:w-[100%] bg-[#33B319] text-white font-bold rounded-[3rem] hover:bg-[#33B319] hover:bg-opacity-70"
                        onClick={(e) => claimHandler(e)}
                      >
                        CLAIM
                      </Button>
                    </div>
                  )}
                </div>
              </Grid>
            </div>
          )}

          <Dialog
            sx={{
              zIndex: '1600 !important',
            }}
            onClose={handleCloseStakeDialog}
            open={openStakeDialog}
            fullWidth={false}
            PaperProps={{
              style: {
                backgroundColor: '#F1F5F9',
                boxShadow: 'none',
                borderRadius: '16px',
              },
            }}
          >
            <DialogTitle id="badge-icon-dialog">
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <IconButton
                  aria-label="close"
                  onClick={handleCloseStakeDialog}
                  className="text-[#090B0A]"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
            <div className="px-52 pb-68">
              <Typography className="font-bold uppercase text-18 pb-36 font-futuraPtHeavy">
                STAKE
              </Typography>
              <div>
                <Typography className="text-15 font-futuraPtHeavy uppercase tracking-wide leading-normal text-replayGreen">
                  Balance
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 800 }}
                  className="text-24 font-futuraPtHeavy text-[#090B0A] leading-tight text-[#090B0A]"
                  title={currentBalance + ' ' + tokenSymbol}
                >
                  {currentBalance
                    ? Number(currentBalance).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) +
                      ' ' +
                      tokenSymbol
                    : '0.00 ' + tokenSymbol}
                </Typography>
              </div>
              <div className="pt-24">
                <TextField
                  type="number"
                  error={
                    stakeAmount !== null &&
                    (stakeAmount <= 0.0 || stakeAmount > Number(currentBalance))
                  }
                  helperText={stakeInputErrorMessage}
                  value={stakeAmount || ''}
                  variant="outlined"
                  label="Enter Stake Amount"
                  InputLabelProps={{
                    style: {
                      fontSize: '1.3rem',
                    },
                    sx: {
                      color: '#7d7c7c',
                      '&.Mui-focused': {
                        color: 'black',
                      },
                    },
                  }}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: Number(currentBalance).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }),
                  }}
                  onChange={(e) => setStakeAmount(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      fontSize: '1.3rem',
                      '& fieldset': {
                        borderColor: '#7d7c7c',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      color: 'black',
                      borderColor: '#7d7c7c',
                      borderWidth: 2,
                      borderRadius: 8,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      color: 'black',
                      borderColor: '#7d7c7c',
                    },
                    '.MuiFormHelperText-root': {
                      fontSize: '1.1rem',
                    },
                  }}
                  className="w-full"
                />
              </div>
              <div className="pt-36">
                <Stack direction="row" spacing={1} className="flex justify-end">
                  <Button
                    className="rounded-[3rem] text-[1.3rem] font-futuraPtBook font-bold bg-[#33B319] hover:bg-[#33B319] hover:bg-opacity-70"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={(e) => stakeHandler(e)}
                    disabled={
                      stakeAmount === null ||
                      stakeAmount <= 0.0 ||
                      stakeAmount > Number(currentBalance)
                    }
                  >
                    STAKE
                  </Button>
                  <Button
                    className="rounded-[3rem] bg-[#090B0A] text-[1.3rem] font-futuraPtBook font-bold hover:bg-[#090B0A] hover:bg-opacity-70"
                    variant="contained"
                    color="primary"
                    onClick={() => handleCloseStakeDialog()}
                  >
                    CANCEL
                  </Button>
                </Stack>
              </div>
            </div>
          </Dialog>

          <Dialog
            sx={{
              zIndex: '1600 !important',
            }}
            onClose={handleCloseUnstakeDialog}
            open={openUnstakeDialog}
            fullWidth={false}
            PaperProps={{
              style: {
                backgroundColor: '#F1F5F9',
                boxShadow: 'none',
                borderRadius: '16px',
              },
            }}
          >
            <DialogTitle id="badge-icon-dialog">
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <IconButton
                  aria-label="close"
                  onClick={handleCloseUnstakeDialog}
                  className="text-[#090B0A]"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
            <div className="px-52 pb-68">
              <Typography className="font-bold uppercase text-18 pb-36 font-futuraPtHeavy">
                UNSTAKE
              </Typography>
              <div>
                <Typography className="text-15 font-futuraPtHeavy uppercase tracking-wide leading-normal text-replayGreen">
                  STAKED + REWARD (EST.)
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 800 }}
                  className="text-24 font-futuraPtHeavy text-[#090B0A] leading-tight text-[#090B0A]"
                  title={currentStakedRewards + ' ' + tokenSymbol}
                >
                  {currentStakedRewards
                    ? Number(currentStakedRewards).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) +
                      ' ' +
                      tokenSymbol
                    : '0.00 ' + tokenSymbol}
                </Typography>
              </div>
              <div className="pt-24">
                <TextField
                  type="number"
                  error={unstakePercent !== null && (unstakePercent <= 0 || unstakePercent > 100)}
                  helperText={unstakeInputErrorMessage}
                  value={unstakePercent || ''}
                  variant="outlined"
                  label="Enter Unstake %"
                  InputLabelProps={{
                    style: {
                      fontSize: '1.3rem',
                    },
                    sx: {
                      color: '#7d7c7c',
                      '&.Mui-focused': {
                        color: 'black',
                      },
                    },
                  }}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 100,
                  }}
                  onChange={(e) => setUnstakePercent(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      fontSize: '1.3rem',
                      '& fieldset': {
                        borderColor: '#7d7c7c',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      color: 'black',
                      borderColor: '#7d7c7c',
                      borderWidth: 2,
                      borderRadius: 8,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      color: 'black',
                      borderColor: '#7d7c7c',
                    },
                    '.MuiFormHelperText-root': {
                      fontSize: '1.1rem',
                    },
                  }}
                  className="w-full"
                />
              </div>
              <div className="pt-16">
                <Typography className="text-15 font-futuraPtBook italic tracking-wide leading-normal text-gray-600">
                  *{tokenSymbol} will be claimable after a ~5 day cooldown
                </Typography>
              </div>
              <div className="pt-32">
                <Stack direction="row" spacing={1} className="flex justify-end">
                  <Button
                    className="rounded-[3rem] bg-[#33B319] text-[1.3rem] font-futuraPtBook font-bold hover:bg-[#33B319] hover:bg-opacity-70"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={(e) => unstakeHandler(e)}
                    disabled={
                      unstakePercent === null || unstakePercent <= 0 || unstakePercent > 100
                    }
                  >
                    UNSTAKE
                  </Button>
                  <Button
                    className="rounded-[3rem] bg-[#090B0A] text-[1.3rem] font-futuraPtBook font-bold hover:bg-[#090B0A] hover:bg-opacity-70"
                    variant="contained"
                    color="primary"
                    onClick={() => handleCloseUnstakeDialog()}
                  >
                    CANCEL
                  </Button>
                </Stack>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default Staking;
